(function (App, $, window) {
  /** @type {Boolean} Is local debug active? */
  const localDebug = false;
  /** @type {Boolean} Is debug active? */
  const debug = App.hasDebug() && localDebug;
  /** @type {Function} Log helper */
  const { log } = App.components.Debugger(debug, 'Lazyload');

  /** @type {jQuery} A jQuery object of the window */
  const $window = $(window);

  /** @type {Blazy} Blazy variable */
  let lazy = null;

  /** @type {Integer} Used to save the timer of the window resize event */
  let timer = null;

  /** @type {Set} A cache which will store already loaded images sources */
  const cache = new Set();

  /**
   * Add the given URL to the cache
   *
   * @param  {String} url The URL to add
   * @return {void}
   */
  function addToCache(url) {
    if (!url) {
      return;
    }

    cache.add(url);
  }

  /**
   * Test if the given url is cached
   *
   * @param  {String}  url The URL to test
   * @return {Boolean}     Is it cached?
   */
  function isCached(url) {
    return cache.has(url);
  }

  /**
   * Init the lazyload
   *
   * @return {void}
   */
  function init() {
    lazy = new window.Blazy({
      selector: '[data-src]',
      successClass: 'is-loaded',
      errorClass: 'has-error',
      success(element) {
        const src = element.getAttribute(this.src);
        const srcSet = element.getAttribute(this.srcSet);
        addToCache(src);
        addToCache(srcSet);

        const $imgContainer = element.closest('.figure__img-container');
        if ($imgContainer !== null) {
          $imgContainer.classList.add('figure__img-container--hide-loader');
        }

        $window.trigger('resizeend');
      },
      offset: window.innerHeight,
    });
    lazy.isCached = isCached;
    lazy.cache = cache;
    App.components.lazy = lazy;

    log(lazy);
  }

  /**
   * Revalidate the lazy load
   *
   * @return {void}
   */
  function revalidate() {
    // Do not revalidate too soon
    if (!lazy || typeof lazy.revalidate !== 'function') {
      return;
    }

    lazy.revalidate();
  }

  /**
   * We instantiate the lazyload here, and attach it to
   * the global `App.components` object to make it available
   * accrosse the whole project.
   */
  $window.on({
    load: init,
    resize() {
      clearTimeout(timer);
      timer = setTimeout(revalidate, 300);
    },
  });
})(App, jQuery, window);
